import { Row, Col } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { getIngredientsData, getMappedValues } from './helper';
import IngredientsSection from './IngredientsSection';
import Sidebar from './Sidebar';
import { useSelector } from 'react-redux';
import { updateRawData } from '@utils/index';

interface SubCategoryProps {
  formik: any;
  isSubCategoryDisabled: boolean;
  subCategoriesOption: any;
  subCategoriesArray: any[];
  ingredientRef?: any;
  submitIngredients?: () => void;
  setProductSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
  measurementsData: any[];
  prepListData: any[];
  forQuotePopUp: boolean;
  productListFromQuote?: Array<any>;
}

const SubCategory: React.FC<SubCategoryProps> = ({
  formik,
  isSubCategoryDisabled = true,
  subCategoriesOption = [],
  ingredientRef,
  submitIngredients,
  setProductSubmit,
  measurementsData = [],
  prepListData = [],
  forQuotePopUp,
  productListFromQuote
}) => {
  const { id } = useParams();
  const [currentSubCategory, setCurrentSubCategory] = useState<number | null>(0);
  const parentProductValues = useSelector((state: any) => state.rawData?.parentProductValues);
  const [isClearWarningModalVisible, setIsClearWarningModalVisible] = useState(false);
  const [primarySubcategoryIngredients, setPrimarySubcategoryIngredients] = useState({
    isTherePrimarySubcategoryIngredients: false,
    primarySubcategoryIngredientsData: {}
  });

  const { setFieldValue, values, errors, handleSubmit } = formik;

  const handleParentErrors = () => {
    if (!!errors && !!Object.keys(errors)?.length) {
      handleSubmit();
      return true;
    }
    return false;
  };

  useEffect(() => {
    updateRawData({
      parentProductValues: {
        product_name: values?.product_name,
        product_type_id: values?.product_type,
        menus: values?.menus,
        master_category: values?.category,
        product_id: id,
        is_one_time_product: values?.is_one_time_product
      }
    });
  }, [
    id,
    values?.category,
    values?.menus,
    values?.product_name,
    values?.product_type,
    values?.is_one_time_product
  ]);

  useEffect(() => {
    if (values?.sub_category_array?.length > 1) {
      const subCategoryId = values?.sub_category_array?.find((item: any) => item?.is_primary)?.id;
      const productId = id ? id : values?.id ? values?.id : '';
      if (!!subCategoryId && !!productId) setPrimarySubcategoryData(productId, subCategoryId);
    }
  }, [values?.sub_category_array, currentSubCategory]);

  const setPrimarySubcategoryData = async (id: string, subCategoryId: number) => {
    if (id) {
      const res = await getIngredientsData(id, subCategoryId);
      setPrimarySubcategoryIngredients({
        isTherePrimarySubcategoryIngredients: !!res?.data?.is_primary,
        primarySubcategoryIngredientsData: getMappedValues(
          res?.data,
          {},
          parentProductValues,
          subCategoryId,
          forQuotePopUp
        )
      });
    }
  };

  const setSubCategories = (values: any) => {
    setFieldValue('sub_categories', values);
  };

  return (
    <div style={{ border: '1px solid #CDCDCD', borderRadius: '5px' }}>
      <Row>
        <Col md={6} xs={24} style={{ borderRight: '1px solid #CDCDCD' }}>
          <Sidebar
            subCategoriesOption={subCategoriesOption}
            isSubCategoryDisabled={isSubCategoryDisabled}
            formik={formik}
            currentSubCategory={currentSubCategory}
            setCurrentSubCategory={setCurrentSubCategory}
            setSubCategories={setSubCategories}
            submitIngredients={submitIngredients}
            forQuotePopUp={forQuotePopUp}
          />
        </Col>
        <Col md={18} xs={24}>
          <IngredientsSection
            productListFromQuote={productListFromQuote}
            setProductSubmit={setProductSubmit}
            forQuotePopUp={forQuotePopUp}
            ref={ingredientRef}
            subCategoryId={values?.sub_category_array?.[`${currentSubCategory}`]?.id}
            currentSubCategory={currentSubCategory}
            isClearWarningModalVisible={isClearWarningModalVisible}
            setIsClearWarningModalVisible={setIsClearWarningModalVisible}
            measurementsData={measurementsData}
            prepListData={prepListData}
            primarySubcategoryIngredients={primarySubcategoryIngredients}
            handleParentErrors={handleParentErrors}
            formik={formik}
            setPrimarySubcategoryData={setPrimarySubcategoryData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SubCategory;
